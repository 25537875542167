body {
	font-family: Roboto, sans-serif;
	color: #444;
	font-size: 1.05rem;
	line-height: 1.55rem;
	font-weight: 400;
}

#printHeader,
#printFooter {
	display: none;
}


h1 {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding-top: 0.125em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #16318c;
	font-size: 2.75rem;
	line-height: 3.45rem;
	font-weight: 700;
	text-transform: uppercase;
}

h2 {
	margin-top: 1rem;
	margin-bottom: 0.5em;
	padding-top: 0.125em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #16318c;
	font-size: 1.9rem;
	line-height: 2.4rem;
	font-weight: 700;
}

h3 {
	margin-top: 1.5em;
	margin-bottom: 0.5em;
	padding-top: 0.125em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #16318c;
	font-size: 1.7rem;
	line-height: 2.3rem;
	font-weight: 700;
}

h4 {
	margin-top: 1em;
	margin-bottom: 0.5em;
	padding-top: 0.125em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #16318c;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 700;
}

h5 {
	margin-top: 1.375em;
	margin-bottom: 0.75em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #16318c;
	font-size: 1.2rem;
	line-height: 1.7rem;
	font-weight: 700;
}

h6 {
	margin-top: 1.25em;
	margin-bottom: 1em;
	padding-top: 0.125em;
	font-family: 'Roboto Condensed', sans-serif;
	color: #16318c;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
	text-transform: uppercase;
}

p {
	margin-top: 0em;
	margin-bottom: 0.75em;
}

a {
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: #163a59;
	text-decoration: none;
}

a:hover {
	color: #000000;
	text-decoration: none;
}

ul {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.25em;
}

ol {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

li {
	margin-bottom: 0.25em;
}

.label,
label {
	display: block;
	margin-bottom: 0.25em;
	line-height: 1.25em;
	font-weight: 700;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
textarea,
select {
	font-size: 0.875em;
	line-height: 1.42857143;
	padding: 4px 10px;
	border: 1px solid #878787;
	color: #333333;
}

strong {
	font-weight: 700;
	letter-spacing: 0.02em;
}

em {
	font-style: italic;
	letter-spacing: 0.02em;
}

blockquote {
	margin-top: 1rem;
	margin-bottom: 1.5rem;
	padding: 1.25rem 1.5rem;
	border-left: 5px solid #76a009;
	background-color: #e5eef2;
	color: #000;
	font-size: 1.25rem;
	line-height: 1.9rem;
}

.button,
.primary,
.secondary,
.tertiary,
button[type="submit"] {
	display: inline-block;
	margin-top: 0.5rem;
	margin-right: 1.25rem;
	margin-bottom: 0.5rem;
	padding: 0.65rem 1.5rem;
	border-style: solid;
	border-width: 1px;
	border-color: #16318c;
	background-color: #16318c;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-family: Roboto, sans-serif;
	color: #fff;
	line-height: 1.375rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
}

.button:hover,
.primary:hover {
	border-style: solid;
	border-width: 1px;
	border-color: #16318c;
	background-color: #fff;
	color: #16318c;
}

.secondary,
.button.secondary {
	border-style: solid;
	border-color: #ffdb2b;
	background-color: #ffdb2b;
	color: #16318c;
}

.secondary:hover,
.button.secondary:hover {
	border-style: solid;
	border-color: #16318c;
	background-color: #fff;
	color: #16318c;
}

.tertiary,
.button.tertiary {
	margin-right: 0rem;
	padding: 0.5rem 1rem 0.35rem;
	border-style: none;
	background-color: #444;
	color: #fff;
}

.tertiary:hover,
.button.tertiary:hover {
	background-color: #2d3033;
	color: #fff;
}

.button.footer-button {
	margin-top: 0.25em;
	padding-bottom: 0.625em;
	background-color: #163a59;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 1em;
	line-height: 1.25em;
}

.button.footer-button:hover {
	background-color: #000;
}

.button.right-sidebar-button {
	font-size: 1rem;
}

.button.header-search-button {
	width: 2.5rem;
	margin-top: 0em;
	margin-right: 0rem;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-radius: 0px;
	background-color: #fff;
	background-image: var( --search-black );
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	opacity: 0.65;
	color: transparent;
	font-size: 0.875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.button.header-search-button:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.button.add-to-cart {
	max-width: 65%;
	margin-right: 0.5em;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	flex: 1;
}

.button.slider-button {
	position: relative;
	margin-top: 0.625em;
	margin-bottom: 0em;
	padding: 0.5rem 1rem 0.38rem 3.125rem;
	border-style: none;
	background-color: hsla(0, 0%, 100%, 0.65);
	font-family: 'Roboto Condensed', sans-serif;
	color: #16318c;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 700;
	text-align: left;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
}

.button.slider-button:hover {
	padding-left: 3.5rem;
	background-color: hsla(0, 0%, 100%, 0.8);
	color: #041d6f;
}

.button.slider-button.slide-2 {
	margin-left: 0rem;
}

.container {
	position: relative;
	max-width: 1200px;
	padding-right: 25px;
	padding-left: 25px;
}

.nav-section {
	background-color: #fff;
	background-image: -webkit-gradient(linear, left top, right top, from(#fff), color-stop(64%, #ffdb2b), color-stop(101%, #fed305));
	background-image: linear-gradient(90deg, #fff, #ffdb2b 64%, #fed305 101%);
	background-position: 0px 0px;
	background-size: auto;
}

.nav-container {
	position: relative;
	max-width: 100%;
}

.nav-link {
	display: inline-block;
	padding: 1.25rem 0.25rem 1rem;
	flex: 1 0 auto;
	box-shadow: 0 -3px 0 0 transparent, 1px 0 0 0 hsla(0, 0%, 100%, 0.5);
	font-family: 'Roboto Condensed', sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.375rem;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0em;
	text-transform: uppercase;
}

.nav-link:hover {
	background-color: #041d6f;
	box-shadow: 0 -5px 0 0 #2343b1, 1px 0 0 0 hsla(0, 0%, 100%, 0.5);
	color: #fff;
}

.nav-link.selected,
.nav-link.w--current {
	background-color: transparent;
	box-shadow: 1px 0 0 0 hsla(0, 0%, 100%, 0.5);
	color: #fff;
}

.nav-link.selected:hover,
.nav-link.w--current:hover {
	background-color: #041d6f;
	box-shadow: 0 -5px 0 0 #ffdb2b, 1px 0 0 0 hsla(0, 0%, 100%, 0.5);
	color: #fff;
}

.nav-link.mobile {
	display: none;
}

.nav-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	width: 100%;
	float: left;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	border-left: 1px solid hsla(0, 0%, 100%, 0.5);
	margin: 0;
	padding: 0;
}

.logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	margin-top: 0.75em;
	margin-bottom: 1em;
	margin-left: 0.375em;
	padding-right: 0.25rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-align-self: center;
	align-self: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	order: -1;
	border-bottom-style: none;
	color: #000;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-style: italic;
	font-weight: 700;
	transition-property: none;
}

.logo:hover {
	color: rgba(0, 0, 0, 0.65);
}

.logo.w--current {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-right: 0rem;
}

.footer-section {
	position: relative;
	border-top: 10px solid #ffdb2b;
	background-color: #fff;
	color: #3e3f3d;
	font-size: 0.9375rem;
	line-height: 1.625em;
}

.row.shop-detail-row {
	margin-bottom: 1.5em;
}

.row.footer-signoff-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	padding-top: 1.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.row.home-cbox-row-3 {
	display: block;
	margin-bottom: 4em;
}

.row.home-cbox-row-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
}

.column {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
}

.column.shop-detail-image-col {
	padding-right: 20px;
}

.column.footer-signoff-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.column.footer-signoff-column.right-signoff-column {
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
}

.logo-image {
	width: 375px;
	float: left;
}

.footer-signoff-section {
	padding-bottom: 1.5rem;
	background-color: transparent;
	font-size: 0.8rem;
	line-height: 1rem;
}

.copyright-text {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0rem;
}

.footer-signoff-list {
	display: inline-block;
	margin: 0rem 0.25rem;
	padding-left: 0px;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-bottom: 0em;
	padding-top: 0.125em;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.footer-signoff-grip {
	width: 133px;
	height: 28px;
	margin-top: 2px;
	padding-left: 32px;
	float: right;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	order: 1;
	border-bottom-style: none;
	border-bottom-width: 0px;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #606060;
	font-size: 8px;
	line-height: 9px;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-signoff-grip:hover {
	color: #606060;
}

#GRIPFooterLogo {
	padding-top: 0 !important;
}

.footer-column-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	width: 50%;
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
	padding-right: 1rem;
	clear: both;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}

.footer-column-wrap.footer-social-column {
	max-width: 200px;
}

.footer-column-wrap.footer-updates-column {
	max-width: 45%;
	margin-top: -0.75rem;
	margin-bottom: -0.75rem;
	padding-top: 1.5rem;
	padding-right: 1.5rem;
	padding-bottom: 2.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	border-left: 1px none rgba(0, 0, 0, 0.1);
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.5)), color-stop(60%, hsla(0, 0%, 100%, 0.4)), color-stop(95%, hsla(0, 0%, 100%, 0)));
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0.4) 60%, hsla(0, 0%, 100%, 0) 95%);
	box-shadow: 0 -10px 0 0 rgba(0, 0, 0, 0.5);
}

.footer-column-title {
	margin-top: 0.5em;
	margin-bottom: 0.375em;
	padding-top: 0.125em;
	color: #16318c;
	font-size: 1.375em;
	line-height: 1.25em;
	letter-spacing: 0.03em;
	text-transform: uppercase;
}

.footer-column-title.footer-notifications-title {
	color: #16318c;
	font-size: 1.4125em;
	line-height: 1.25em;
}

.header-content-right-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	margin-right: 1em;
	padding-bottom: 0rem;
	float: right;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: space-around;
	justify-content: space-around;
	-webkit-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	align-items: flex-end;
	-webkit-align-self: stretch;
	align-self: stretch;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	order: 1;
}

.header-contents-wrap {
	position: relative;
	background-color: transparent;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.breadcrumb-list {
	margin: 0em 0.125rem 1.5rem;
	padding-left: 0px;
	font-size: 0.8rem;
	line-height: 1rem;
	font-weight: 400;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.header-menu-wrap {
	background-color: #16318c;
}

.form-wrap {
	margin-bottom: 0px;
}

.form-wrap.pagination-sort {
	position: relative;
	display: inline-block;
	text-align: left;
}

.form.sort-by-form {
	position: relative;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 0.875em;
}

.form-field.right-sidebar-field {
	width: 100%;
	margin-top: 0.375em;
	margin-bottom: 1.25em;
	padding-right: 4px;
	padding-left: 8px;
}

.form-field.right-sidebar-field.miles-from {
	width: 52%;
}

.form-field.right-sidebar-field.zip-code {
	width: 45%;
}

.form-field.sort-by-dropdown {
	height: 2.25rem;
	margin-bottom: 0em;
	padding-right: 6px;
	padding-left: 6px;
}

.form-field.header-search-field {
	width: 120px;
	height: 2.25rem;
	margin-bottom: 0em;
	border-style: none;
	border-width: 0px;
	border-color: rgba(0, 0, 0, 0.15);
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #3e3f3d;
	font-size: 0.75rem;
}

.form-field.header-search-field:focus {
	width: 160px;
	border: 0px none transparent;
	background-color: #fff;
}

.form-field.header-search-field::-webkit-input-placeholder {
	color: #797676;
}

.form-field.header-search-field::placeholder {
	color: #797676;
}

.form-field.shop-detail-form-field {
	width: 100%;
	margin-bottom: 0.25em;
}

#session-message-wrapper .success {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	padding: 1.25rem;
	background-color: #1f8927;
	color: #fff;
}

#session-message-wrapper .error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #d11616;
	color: #fff;
}

.secondary-nav-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
	font-size: 1rem;
	line-height: 1.25rem;
}

.secondary-nav-list-item {
	margin-right: 0.75rem;
	margin-bottom: 0rem;
	margin-left: 0.75rem;
	float: left;
	letter-spacing: 0.01rem;
}

.secondary-nav-list-item.first {
	margin-left: 0rem;
	border-left-style: none;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.left-nav-column {
	padding-right: 20px;
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
	border-top: 1px none #e6e6e6;
	box-shadow: 0 1px 0 0 #e6e6e6;
}

.left-nav-list-item {
	margin-bottom: 0em;
	box-shadow: 0 -1px 0 0 #e6e6e6;
}

.left-nav-list-link {
	display: block;
	padding: 0.6rem 0.75rem 0.6rem 0.5rem;
	border-left: 3px solid transparent;
	color: #444;
	font-size: 1.1rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-align: left;
}

.left-nav-list-link:hover {
	border-left-color: #16318c;
	-webkit-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #163a59;
}

.left-nav-list-link.selected,
.left-nav-list-link.w--current {
	border-left-color: #ffdb2b;
	background-color: rgba(172, 173, 175, 0.1);
	color: #444;
}

.left-nav-list-link.selected:hover,
.left-nav-list-link.w--current:hover {
	-webkit-transform: none;
	transform: none;
}

.left-nav-list-link.current-path-not-current-page.selected,
.left-nav-list-link.current-path-not-current-page.w--current {
	border-left-color: #ffdb2b;
	background-color: transparent;
	color: #444;
	font-weight: 400;
}

.left-nav-nested-list {
	display: block;
	overflow: hidden;
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 0px 0em 0px 0.75em;
	border-top: 1px solid #e6e6e6;
}

.left-nav-nested-list-item {
	margin-bottom: 0em;
	box-shadow: 0 1px 0 0 #e6e6e6;
}

.grandchild-left-nav-link {
	display: block;
	padding: 0.6rem 1.25rem 0.6rem 0.5rem;
	border-left: 2px solid transparent;
	color: #444;
	font-size: 0.95rem;
	line-height: 1.25rem;
	font-weight: 400;
	letter-spacing: 0.03em;
}

.grandchild-left-nav-link:hover {
	border-left-color: #16318c;
	-webkit-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #163a59;
}

.grandchild-left-nav-link.selected,
.grandchild-left-nav-link.w--current {
	border-left-color: #ffdb2b;
	background-color: rgba(172, 173, 175, 0.1);
	color: #444;
}

.grandchild-left-nav-link.selected:hover,
.grandchild-left-nav-link.w--current:hover {
	-webkit-transform: none;
	transform: none;
}

.grandchild-left-nav-link.current-path-not-current-page.selected,
.grandchild-left-nav-link.current-path-not-current-page.w--current {
	background-color: transparent;
}

.sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1em;
	padding-top: 0.75em;
	padding-right: 3px;
	padding-left: 3px;
	border-top: 2px solid #ffdb2b;
	font-size: 1rem;
	line-height: 1.5rem;
}

.sidebar-section-title-h4 {
	margin-top: 0.25em;
	margin-bottom: 0.5em;
	color: #16318c;
	font-size: 1.3rem;
	line-height: 1.8rem;
}

.sidebar-list {
	padding-left: 0px;
}

.sidebar-list-item {
	margin-bottom: 0.5em;
	padding-left: 0px;
}

.section-wrap.inside-content-section {
	background-color: #ececec;
}

.secondary-nav-icon {
	width: 1.625rem;
	height: 1.625rem;
	margin-right: 0.375rem;
	padding: 6px;
	float: left;
	background-color: #5b636b;
}

.secondary-nav-icon.idea-icon {
	width: 2em;
	height: 2em;
	margin-right: 0.4125em;
	padding: 7px 7px 10px;
	border-radius: 50%;
	background-color: #163a59;
}

.secondary-nav-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.secondary-nav-text.phone-text {
	padding-top: 0.125em;
}

.secondary-nav-text.indicator-text {
	position: relative;
	min-width: 2em;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	padding: 4px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-align-self: center;
	align-self: center;
	border-radius: 5px;
	background-color: #fff;
	box-shadow: none;
	font-size: 0.875em;
	line-height: 1.5em;
	text-align: center;
	letter-spacing: 0em;
}

.secondary-nav-list-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	border-bottom-style: none;
	color: rgba(0, 0, 0, 0.75);
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
}

.secondary-nav-list-link:hover {
	-webkit-transform: translate(0px, -3px);
	transform: translate(0px, -3px);
	color: #64802c;
}

.slider-section {
	position: relative;
	margin-top: 5px;
	background-color: #f8f9fa;
}

.slider {
	display: block;
	height: 45vw;
	max-height: 540px;
	max-width: 100%;
	min-height: 150px;
	margin-right: auto;
	margin-left: auto;
	background-color: transparent;
}

.slider-arrow {
	top: 50%;
	bottom: auto;
	width: 5rem;
	height: 6rem;
	background-color: transparent;
	opacity: 0.6;
	-webkit-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	color: #83888d;
	font-size: 3rem;
}

.slider-arrow:hover {
	background-color: #16318c;
	opacity: 1;
	color: #fff;
}

.slider-arrow:focus {
	background-color: transparent;
	opacity: 1;
	color: #163a59;
}

.slider-indicator {
	overflow: hidden;
	height: 0.25em;
	margin-bottom: 1em;
	padding-top: 0rem;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 1.5rem;
	line-height: 0.5em;
}

.home-section {
	background-color: #f3f5f7;
}

.home-image-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 25vw;
	max-height: 320px;
	min-height: 140px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	border-top: 5px solid #163a59;
	background-color: #f3f5f7;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.home-image-link:hover {
	opacity: 0.85;
}

.home-image-link.large-image-link {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	flex-grow: 1;
	-webkit-flex-basis: 35%;
	flex-basis: 35%;
	border-top-color: #ffdb2b;
}

.home-content-box-wrap {
	position: relative;
	width: 100%;
}

.home-content-box-wrap.intro-content-box-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}

.home-content-box-title {
	margin-top: 0rem;
	margin-bottom: 0.25em;
	padding-top: 0.125em;
	font-size: 1.625em;
	line-height: 1.125em;
	text-transform: uppercase;
}

.home-content-box-title.hero-cbox-title {
	font-size: 1.75em;
}

.home-content-box-title.intro-cbox-title {
	padding-top: 0.125em;
	font-size: 2.25em;
	line-height: 1.125em;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.home-cbox-learn-more {
	position: absolute;
	left: 0px;
	bottom: 0px;
	z-index: 500;
	display: inline-block;
	margin-top: 0rem;
	padding-top: 0.38rem;
	padding-bottom: 0.25rem;
	border-bottom: 1px solid transparent;
	-webkit-transition-property: border-color;
	transition-property: border-color;
	font-family: 'Roboto Condensed', sans-serif;
	color: #16318c;
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 700;
	letter-spacing: 0.03rem;
	text-transform: uppercase;
}

.home-cbox-learn-more:hover {
	border-bottom: 1px solid #797676;
	color: #16318c;
}

.home-cbox-learn-more.intro-cbox-learn-more {
	position: relative;
	padding: 0.65rem 1rem 0.55rem;
	border: 1px none #000;
	background-color: #ffdb2b;
	-webkit-transition: border-color 250ms ease, background-color 250ms ease;
	transition: border-color 250ms ease, background-color 250ms ease;
	color: #16318c;
	font-size: 1em;
	font-weight: 700;
	letter-spacing: 0.07em;
	text-shadow: none;
}

.home-cbox-learn-more.intro-cbox-learn-more:hover {
	border-style: none;
	border-width: 1px;
	border-color: #16318c;
	background-color: #fff;
	color: #16318c;
}

.home-cbox-learn-more.learn-tabmobile {
	display: none;
}

.home-cbox-learn-more.learn-tabmobile-row-1 {
	display: none;
}

.slider-image {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	width: 100%;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	background-position: 100% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-image.slide-2 {
	background-position: 50% 75%;
}

.slider-image.slide-3 {
	background-position: 50% 35%;
	background-size: cover;
}

.slider-caption-outer-wrap {
	position: relative;
	top: 50%;
	display: block;
	max-width: 70%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1rem;
	padding-bottom: 2rem;
	-webkit-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
	color: #fff;
	text-align: left;
}

.shop-item-wrap {
	margin-bottom: 20px;
	padding-bottom: 10px;
}

.shop-item-wrap.flex {
	width: 31.33%;
	margin-right: 1%;
	margin-left: 1%;
}

.shop-item-wrap.flex.item-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	width: 98%;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	border-bottom: 1px solid #e6e6e6;
}

.product-image-link {
	display: block;
	margin-bottom: 0.75em;
	padding: 0px;
	text-align: center;
}

.product-image-link.list-view-link {
	max-width: 25%;
}

.product-info-image {
	display: inline-block;
}

.product-info-title {
	margin-top: 0.5em;
	font-size: 1.375em;
	line-height: 1.375em;
}

.product-info-abstract {
	font-size: 0.875em;
	line-height: 1.5em;
}

.product-info-price {
	display: inline-block;
	margin-right: 0.5em;
	font-size: 1em;
	line-height: 1.25em;
	font-weight: 600;
	text-transform: uppercase;
}

.product-info-price.old-price {
	color: #a3a3a3;
	text-decoration: line-through;
}

.product-info-price.sale-price {
	color: #bf6034;
}

.inside-page-header-content-wrap {
	margin-bottom: 1.5em;
	padding-bottom: 0em;
	border-bottom: 1px solid #e6e6e6;
}

.inside-page-header-section {
	position: relative;
	display: block;
	height: 500px;
	background-color: #fff;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 90.2%, 0.38)), to(#ececec)), var( --paw-pattern );
	background-image: linear-gradient(180deg, hsla(0, 0%, 90.2%, 0.38), #ececec), var( --paw-pattern );
	background-position: 0px 0px, 50% 45%;
	background-size: auto, 720px;
	color: #3e3f3d;
}

.inside-content-beckground {
	position: relative;
	display: block;
	max-width: 1280px;
	margin-top: -425px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1.5rem;
	padding-bottom: 4rem;
	border-top: 7px solid #fed305;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	background-color: #fff;
}

.main-content-wrap.nested-content-wrap {
	margin-right: 0.5em;
}

.header-menu-inside-wrap {
	display: block;
	max-width: 1170px;
	margin-right: auto;
	margin-left: auto;
}

.intro-text-style {
	font-size: 1.2rem;
	line-height: 1.8rem;
}

.home-cbox-text-wrap {
	position: relative;
	padding-top: 1.125em;
	padding-bottom: 2rem;
	font-size: 1.0625rem;
	line-height: 1.5em;
}

.home-cbox-text-wrap.hero-cbox-text-wrap {
	font-size: 1.25rem;
	line-height: 1.5em;
}

.home-cbox-text-wrap.intro-cbox-text-wrap {
	display: block;
	max-width: 85%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1em;
	padding-bottom: 1em;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	font-family: Khand;
	color: #fff;
	font-size: 1.5rem;
	line-height: 1.5em;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.03em;
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}

.slider-caption-title {
	display: inline;
	padding-top: 0.125em;
	padding-bottom: 0px;
	background-color: hsla(0, 0%, 100%, 0.65);
	box-shadow: 20px 0 0 0 hsla(0, 0%, 100%, 0.65), -20px 0 0 0 hsla(0, 0%, 100%, 0.65);
	font-family: 'Roboto Condensed', sans-serif;
	color: #16318c;
	font-size: 3.5rem;
	line-height: 5.75rem;
	font-weight: 700;
	letter-spacing: 0rem;
	text-transform: uppercase;
}

.footer-social-icons-wrap {
	margin-bottom: 1em;
	padding-left: 1px;
}

.footer-social-icon-link {
	margin-right: 8px;
	margin-bottom: 8px;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.25);
	background-color: #fff;
	opacity: 0.8;
}

.footer-social-icon-link:hover {
	border-color: rgba(0, 0, 0, 0.2);
	background-color: #fff;
	opacity: 1;
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.footer-social-icon-link:focus {
	border-color: #000;
}

.footer-social-icon-image {
	width: 32px;
	height: 32px;
	padding: 2px;
}

.footer-newsletter-wrap {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	flex-grow: 1;
}

.home-cbox-dynamic-feed-item-wrap {
	margin-bottom: 0.75rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	border-bottom: 1px solid rgba(40, 49, 59, 0.15);
	font-size: 0.875rem;
	line-height: 1.375rem;
}

.home-cbox-dynamic-feed-date {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.home-cbox-dynamic-feed-item-title {
	margin-top: 0em;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
}

.left-nav-list-content-wrap {
	display: block;
	height: auto;
}

.footer-link {
	color: #2d3033;
	font-size: 0.95rem;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-link:hover {
	box-shadow: 0 1px 0 0 #797676;
	color: #ffdb2b;
}

.footer-link:focus {
	box-shadow: 0 1px 0 0 #163a59;
}

.footer-link.signoff-link {
	font-size: 0.8rem;
}

.footer-link.signoff-link:hover {
	box-shadow: 0 1px 0 0 #797676;
}

main a {
	border-bottom: 1px solid #797676;
	color: #3e3f3d;
	font-weight: 400;
}

main a:hover {
	border-bottom-color: transparent;
	color: #ffdb2b;
	cursor: pointer;
}

a.w--current {
	color: #28313b;
	font-weight: 700;
}

a.pagination-link {
	margin: 0.5em 0.25em;
	float: left;
	font-weight: 700;
	text-transform: uppercase;
}

a.pagination-link.next {
	float: right;
}

h3 a {
	color: #2343b1;
	font-weight: 700;
}

h3 a:hover {
	color: #ffdb2b;
}

a.content-box-title-link {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #16318c;
}

a.content-box-title-link:hover {
	border-bottom-color: #797676;
	color: #ffdb2b;
}

a.content-box-title-link.intro-cbox-title-link {
	display: inline-block;
	padding-top: 0.125em;
	border-bottom-style: solid;
	border-bottom-color: transparent;
	font-family: 'Roboto Condensed', sans-serif;
	color: #fff;
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
}

a.content-box-title-link.intro-cbox-title-link:hover {
	border-bottom-color: transparent;
	color: #fff;
	text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

h2 a {
	color: #2343b1;
	font-weight: 700;
}

h2 a:hover {
	color: #ffdb2b;
}

a.breadcrumb-link {
	display: inline-block;
	color: #444;
	font-weight: 400;
}

a.breadcrumb-link:hover {
	border-bottom-color: transparent;
	color: #ffdb2b;
	font-weight: 400;
}

h4 a {
	color: #2343b1;
	font-weight: 600;
}

h4 a:hover {
	color: #ffdb2b;
}

h5 a {
	color: #2343b1;
	font-weight: 700;
}

h5 a:hover {
	color: #ffdb2b;
}

h6 a {
	color: #2343b1;
	font-weight: 700;
}

h6 a:hover {
	color: #ffdb2b;
}

h1 a {
	color: #2343b1;
	font-weight: 700;
}

h1 a:hover {
	color: #ffdb2b;
}

.pagination-middle-wrap {
	display: inline-block;
	clear: both;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	flex: 1;
}

.pagination-middle-text {
	display: inline-block;
	margin-right: 0.5em;
	margin-left: 0.5em;
}

.pagination-middle-select {
	display: inline-block;
	width: auto;
	max-height: 2.25rem;
	margin-bottom: 0px;
	padding: 0px 0px 0px 2px;
}

.pagination-wrap {
	margin-bottom: 1.5em;
	padding-top: 0.75em;
	padding-bottom: 0.75em;
	border-top: 1px solid #e6e6e6;
	border-bottom: 1px solid #e6e6e6;
	font-size: 0.875em;
	line-height: 1.25em;
	text-align: center;
}

.pagination-wrap.flex-pagination {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	margin-bottom: 1.5em;
	padding-right: 1%;
	padding-left: 1%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.divider {
	height: 1px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: #e6e6e6;
}

.shop-item-row-wrap-grid-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}

.shop-item-text-wrap.list-view-text-wrap {
	margin-left: 1em;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	flex: 1;
}

.loc-view-buttons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}

.loc-view-button-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	overflow: hidden;
	width: 48%;
	padding-top: 0.5em;
	padding-right: 0.25em;
	padding-bottom: 0.5em;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-align-self: center;
	align-self: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: solid;
	border-width: 2px;
	border-color: rgba(0, 0, 0, 0.5);
	border-radius: 20px;
	opacity: 0.55;
	-webkit-transition: color 150ms ease, background-color 150ms ease, opacity 150ms ease, border-color 150ms ease, box-shadow 150ms ease, all 200ms ease;
	transition: color 150ms ease, background-color 150ms ease, opacity 150ms ease, border-color 150ms ease, box-shadow 150ms ease, all 200ms ease;
	color: #000;
	font-weight: 700;
}

.loc-view-button-wrap:hover {
	border-color: rgba(0, 0, 0, 0.25);
	opacity: 1;
}

.loc-view-button-wrap.w--current {
	border-color: #000;
	opacity: 0.7;
}

.loc-view-button-wrap.w--current:hover {
	opacity: 1;
}

.loc-miles-from-zip-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}

.loc-list-section-title {
	margin-top: 0em;
}

.loc-select-type-form-wrap {
	position: relative;
}

.loc-type-form {
	position: relative;
}

.loc-type-form-checkbox {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	width: 50%;
	margin-bottom: 0.75em;
	-webkit-align-self: center;
	align-self: center;
	line-height: 1.375em;
}

.loc-type-form-checkbox.select-all-wrap {
	font-weight: 700;
}

.loc-type-form-checkbox.sidebar-checkbox {
	width: 100%;
	margin-bottom: 0.5em;
}

.loc-type-form-checkbox.sidebar-checkbox.select-all {
	margin-top: 0.75em;
	font-weight: 600;
}

.loc-type-form-checkbox-box {
	position: relative;
	width: 1.5em;
	height: 1.5em;
	margin-top: 0px;
	margin-right: 0.5em;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	order: -1;
}

.loc-type-form-checkbox-box.select-all-checkbox {
	width: 1.4125em;
	height: 1.4125em;
	margin-right: 0.5em;
}

.loc-type-form-checkbox-label {
	position: relative;
	float: none;
	-webkit-align-self: center;
	align-self: center;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	order: 1;
}

.loc-type-form-checkbox-label.select-all-label {
	font-weight: 700;
	text-transform: uppercase;
}

.loc-type-form-checkboxes-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	margin-top: 0.75em;
	margin-bottom: 1.25em;
	padding-top: 1em;
	padding-bottom: 0.25em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
	border-bottom: 1px solid rgba(84, 159, 196, 0.25);
}

.loc-type-form-checkbox-image {
	position: relative;
	display: block;
	width: 1.5em;
	height: 1.5em;
	margin-right: 0.5em;
}

.loc-sidebar-form-reveal {
	overflow: hidden;
	height: auto;
}

.loc-sidebar-search-reveal-button {
	position: relative;
	display: none;
	margin-bottom: 0em;
	padding: 0.75em 0.5em 0.75em 0.25em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
	font-size: 1.125em;
	line-height: 1.25em;
	font-weight: 600;
	cursor: pointer;
}

.loc-sidebar-search-reveal-button:active {
	color: #777;
}

.loc-refine-search-button-image {
	width: 0.75em;
	height: 0.75em;
	margin-top: 3px;
}

.right-sidebar-refine-search-button-text {
	margin-top: 0em;
	margin-bottom: 0em;
	margin-left: 0.25em;
	font-size: 1.125em;
	line-height: 1.25em;
}

.loc-refine-search-checkbox-wrap {
	padding-top: 0.5em;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
}

.loc-view-button-text {
	margin-left: 0.25em;
}

.loc-view-button-image {
	position: relative;
	display: block;
	width: 1.125rem;
	height: 1.375rem;
}

.pagination-left-side-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.pagination-view-by-icons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	margin-left: 0.5rem;
}

.pagination-view-by-button {
	width: 2.25rem;
	height: 2.25rem;
	margin-left: 0.5rem;
	border: 1px solid #000;
	opacity: 0.35;
}

.pagination-view-by-button:hover {
	opacity: 1;
}

.pagination-middle-group-wrap {
	display: inline-block;
}

.inside-page-content-wrap-no-sidebars {
	margin-right: 0%;
	margin-left: 0%;
}

.nav-drop-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	flex: 1 0 auto;
	border-right: 1px solid hsla(0, 0%, 100%, 0.5);
	margin-bottom: 0;
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	width: 100%;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	order: -1;
	box-shadow: 0 -3px 0 0 transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 100%, 0.5);
}

.nav-drop-toggle:hover {
	background-color: #041d6f;
	box-shadow: 0 -5px 0 0 #ffdb2b;
	color: hsla(0, 0%, 100%, 0.75);
}

.nav-drop-toggle.w--open {
	background-color: #041d6f;
	box-shadow: 0 -5px 0 0 #2343b1;
	color: #fff;
}

.nav-drop-toggle.w--open:hover {
	background-color: rgba(0, 0, 0, 0.25);
}

.nav-drop-icon {
	margin-right: 1rem;
	font-size: 0.6875em;
}

.nav-drop-list {
	overflow: hidden;
}

.nav-drop-list.w--open,
.nav-drop-wrap.open .nav-drop-list {
	left: 0px;
	top: 100%;
	padding: 0.25rem 0em;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	flex: 1;
	background-color: #16318c;
	color: #fff;
}

.nav-drop-list.open-left.w--open,
.nav-drop-wrap.open .nav-drop-list {
	overflow: visible;
	background-color: #16318c;
	background-image: none;
	display: block;
}

.dropdown-ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.dropdown-ul a,
.nav-drop-list-link {
	margin-bottom: 0.125rem;
	padding: 0.75rem 3rem 0.75rem 0.75rem;
	background-color: transparent;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.1rem;
	font-weight: 400;
}

.dropdown-ul a:hover,
.nav-drop-list-link:hover {
	background-color: #041d6f;
	color: #fff;
}

.dropdown-ul a.w--current,
.nav-drop-list-link.w--current {
	box-shadow: inset 3px 0 0 0 #163a59;
	color: #fff;
}

.nav-drop-toggle-link {
	width: 100%;
	padding: 1.25rem 0rem 1rem;
	font-family: 'Roboto Condensed', sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
}

.nav-drop-toggle-link:hover {
	border-top: 1px none #000;
	color: #fff;
}

.nav-drop-wrap.w-dropdown a.selected,
.nav-drop-toggle-link.w--current {
	background-color: rgba(0, 0, 0, 0.2);
	box-shadow: 0 -5px 0 0 #2343b1;
}

.header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	max-width: 1140px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}

.pagination-view-by-icon-image {
	padding: 4px;
}

.shop-item-row-wrap-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	flex-direction: column;
}

.shop-first-heading {
	margin-top: 0em;
}

.shop-header-footer-wrap {
	margin-bottom: 1.5em;
}

.secondary-header-item-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	margin-right: 1rem;
	margin-left: 1rem;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	align-items: stretch;
	font-family: Khand;
	color: #3e3f3d;
	font-size: 1.25rem;
	line-height: 1em;
	font-weight: 700;
	letter-spacing: 0.03em;
	text-transform: uppercase;
}

.secondary-header-item-wrap:hover {
	color: #163a59;
}

.header-content-right-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.secondary-header-item-cta-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	margin-right: 0rem;
	margin-left: 1rem;
	padding: 0.75em 1.5rem 0.61em 3em;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-align-self: stretch;
	align-self: stretch;
	background-color: #3e3f3d;
	background-image: var( --speech-bubble );
	background-position: 21px 50%;
	background-size: 22px 22px;
	background-repeat: no-repeat;
	font-family: Khand;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 1.125rem;
	line-height: 1.25rem;
	font-weight: 600;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.secondary-header-item-cta-link:hover {
	background-color: #163a59;
	color: #fff;
}

.secondary-header-item-cta-link:focus {
	background-color: #3e3f3d;
}

.header-search-wrap {
	margin-bottom: 0px;
	margin-left: 1.25rem;
}

.header-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	background-color: #fff;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
}

.shop-detail-tabs-wrap {
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

.shop-detail-related-items-title {
	margin-top: 0em;
}

.shop-detail-related-items-wrap {
	padding-top: 1.5em;
	border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.shop-detail-tabs-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
}

.shop-detail-tab-link {
	margin-right: 0.5%;
	padding: 0.75em 0.75rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	flex: 1;
	background-color: #e6e6e6;
	color: #000;
	font-weight: 500;
}

.shop-detail-tab-link:hover {
	color: #163a59;
}

.shop-detail-tab-link.w--current {
	background-color: #163a59;
	color: #fff;
}

.shop-detail-tabs-content {
	padding: 1em 0.75rem;
}

.shop-detail-attributes-wrap {
	margin-bottom: 1.5em;
	padding: 1em;
	border: 1px solid #e6e6e6;
}

.shop-detail-lightbox-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	border: 1px solid #e6e6e6;
	text-align: center;
}

.shop-detail-text.product-price {
	font-size: 1.5em;
	line-height: 1.25em;
}

.shop-detail-form-section {
	margin-top: 0.75em;
	margin-bottom: 0.75em;
	padding-top: 0.5em;
	border-top: 1px solid #e6e6e6;
}

.shop-detail-form-buttons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
}

.slider-caption-inner-wrap {
	margin-bottom: 0.5rem;
	padding-top: 0.25rem;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
}

.slider-caption-heading {
	display: inline-block;
	margin-top: 0.25em;
	margin-bottom: 0.25em;
	padding-top: 0.6em;
	padding-bottom: 0.6rem;
	background-color: hsla(0, 0%, 100%, 0.65);
	box-shadow: 20px 0 0 0 hsla(0, 0%, 100%, 0.65), -20px 0 0 0 hsla(0, 0%, 100%, 0.63);
	font-family: Roboto, sans-serif;
	color: #16318c;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 500;
	letter-spacing: 0rem;
	text-shadow: none;
}

.slider-caption-heading p {
	margin-bottom: 0;
}

.home-content-box-section-wrap {
	padding-top: 3em;
	padding-bottom: 0em;
	background-color: hsla(0, 0%, 100%, 0.55);
	background-image: var( --paw-pattern );
	background-position: 50% 50%;
	background-size: 725px;
}

.slider-button-arrow-span {
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	width: 2.25rem;
	height: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	border-right: 1px solid rgba(0, 0, 0, 0.1);
	background-color: hsla(0, 0%, 100%, 0.4);
	background-image: var( --blue-arrow-right );
	background-position: 50% 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
	color: transparent;
	font-size: 0.85rem;
}

.intro-cbox-section {
	margin-top: 4em;
	margin-bottom: 3em;
	padding-top: 3em;
	padding-bottom: 3em;
	background-color: #000;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(22, 49, 140, 0.72)), to(rgba(22, 49, 140, 0.72))), var( --spotlight-alumni-bg );
	background-image: linear-gradient(180deg, rgba(22, 49, 140, 0.72), rgba(22, 49, 140, 0.72)), var( --spotlight-alumni-bg );
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	background-attachment: scroll, fixed;
}

.footer-flex-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	padding-top: 0.75rem;
	padding-bottom: 1.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.footer-signoff-acrobat-link {
	margin-right: 2.5em;
}

.acrobat-reader-image {
	width: 135px;
	height: 34px;
}

.intro-cbox-section-paragraph {
	margin-bottom: 1.5em;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
}

.slider-image-overlay {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.15)));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
}

.footer-phone-span {
	color: #2d3033;
}

.footer-contact-wrap {
	width: 50%;
	padding-right: 1.5rem;
}

.footer-social-block {
	width: 50%;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	flex-grow: 1;
}

.footer-notifications-image {
	width: 6.5vw;
	height: 6.5vw;
	max-height: 4rem;
	max-width: 4rem;
	margin: 1rem 3vw;
	padding: 0px;
	float: left;
	-webkit-align-self: flex-start;
	align-self: flex-start;
}

.content-image-example {
	width: 300px;
	margin-bottom: 30px;
	margin-left: 30px;
	float: right;
}

.shop-detail-dimensions-label {
	min-width: 75px;
	float: left;
	font-weight: 700;
}

.footer-quick-link {
	display: inline-block;
	border-bottom: 1px solid transparent;
	color: #3e3f3d;
	line-height: 1.15rem;
}

.footer-quick-link:hover {
	border-bottom: 1px solid #797676;
	color: #ffdb2b;
}

.footer-quick-link.w--current {
	border-bottom-color: #797676;
}

.great-gchild-left-nav-link {
	display: block;
	padding: 0.5rem 1.25rem 0.4375em 0.5rem;
	border-left: 2px solid transparent;
	color: #444;
	font-size: 0.875rem;
	line-height: 1.2rem;
	font-weight: 400;
	letter-spacing: 0.03em;
}

.great-gchild-left-nav-link:hover {
	border-left-color: #16318c;
	-webkit-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #163a59;
}

.great-gchild-left-nav-link.w--current {
	border-left-color: #16318c;
	background-color: rgba(172, 173, 175, 0.1);
	color: #041d6f;
}

.great-gchild-left-nav-link.w--current:hover {
	-webkit-transform: none;
	transform: none;
}

.sidebar-section-title-h3 {
	margin-top: 0.25em;
	margin-bottom: 0.5em;
	color: #16318c;
	font-size: 1.3rem;
	line-height: 1.8rem;
}

.sidebar-section-title-h2 {
	margin-top: 0.25em;
	margin-bottom: 0.5em;
	color: #16318c;
	font-size: 1.3rem;
	line-height: 1.8rem;
}

.dropdown-toggle {
	margin-bottom: 0.125rem;
	padding: 0.75rem 2rem 0.75rem 0.75rem;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.1rem;
}

.icon {
	display: none;
}

.dropdown {
	width: 100%;
}

.dropdown:hover {
	background-color: #041d6f;
}

.subcategory-dropdown-list {
	left: -5px;
}

.subcategory-dropdown-list.w--open {
	left: 167px;
	top: 0px;
	z-index: 1000;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	background-color: #16318c;
}

.grip-logo-text-box {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text-box.webdevby-black {
	color: #444;
	font-size: 0.55rem;
}

.clients-slider-image {
	position: relative;
	top: 50%;
	display: block;
	max-height: 50px;
	max-width: 100px;
	margin-right: auto;
	margin-left: auto;
	-webkit-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
}

.clients-slider-arrow-icon {
	color: #163a59;
}

.cb-head-wrapper {
	display: block;
	margin-top: 2rem;
	margin-bottom: 1rem;
	-webkit-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.cb-head-wrapper.sponsorships-header-wrapper {
	margin-top: 0rem;
}

.clients-slider-mask {
	position: relative;
	height: 100%;
	padding-right: 2rem;
	padding-left: 2rem;
}

.clients-slider {
	position: relative;
	height: 80px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border-style: none;
	border-width: 1px;
	border-color: rgba(111, 124, 136, 0.15);
	background-color: transparent;
}

.home-content-section {
	display: block;
	padding-top: 3rem;
	padding-bottom: 5rem;
	background-color: #fff;
}

.home-content-section.sponsors-section {
	padding-top: 3rem;
	padding-bottom: 3rem;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	background-color: #fff;
}

.container-2 {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.clients-slider-arrow {
	z-index: 21;
	height: 100%;
	max-width: 2rem;
	-webkit-transition: background-color 300ms ease, color 300ms ease;
	transition: background-color 300ms ease, color 300ms ease;
	color: #01579b;
	font-size: 1.25rem;
}

.clients-slider-arrow:hover {
	background-color: #e4e7eb;
	color: transparent;
}

.cb-heading {
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 1rem;
	color: #0a706b;
	font-size: 2.286rem;
	line-height: 2.986rem;
	font-weight: 400;
}

.cb-heading.clients-cb-head {
	display: block;
	margin-right: auto;
	margin-bottom: 2rem;
	margin-left: auto;
	color: #16318c;
	font-size: 2.286rem;
	line-height: 2.986rem;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
}

.clients-slider-slide {
	position: relative;
	width: 20%;
	height: 60px;
	min-width: 100px;
	margin-top: 10px;
}

.clients-slider-slide.slide-1 {
	border-left: 1px solid rgba(111, 124, 136, 0.07);
}

.header-contact-us {
	position: absolute;
	left: 0px;
	bottom: 0px;
	z-index: 500;
	display: inline-block;
	margin-top: 0rem;
	padding-top: 0.38rem;
	padding-bottom: 0.25rem;
	border-bottom: 1px solid transparent;
	-webkit-transition-property: border-color;
	transition-property: border-color;
	font-family: 'Roboto Condensed', sans-serif;
	color: #16318c;
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 700;
	letter-spacing: 0.03rem;
	text-transform: uppercase;
}

.header-contact-us:hover {
	border-bottom: 1px solid #797676;
	color: #16318c;
}

.header-contact-us.intro-cbox-learn-more {
	position: relative;
	padding: 0.65rem 1.5rem 0.55rem;
	border-bottom-style: none;
	background-color: #fff;
	color: #16318c;
	font-size: 1em;
	font-weight: 600;
	letter-spacing: 0.07em;
	text-shadow: none;
}

.header-contact-us.intro-cbox-learn-more:hover {
	border-bottom-style: none;
	background-color: #16318c;
	color: #fff;
}

.header-contact-us.learn-tabmobile {
	display: none;
}

.header-contact-us.learn-tabmobile-row-1 {
	display: none;
}

.header-contact-us.contact-us-link {
	position: relative;
	padding: 0.65rem 1rem 0.55rem 1rem;
	border: 1px none #000;
	background-color: #16318c;
	-webkit-transition: background-color 250ms ease, border-color 250ms ease;
	transition: background-color 250ms ease, border-color 250ms ease;
	color: #fff;
	font-size: 1em;
	font-weight: 700;
	letter-spacing: 0.07em;
	text-shadow: none;
}

.header-contact-us.contact-us-link:hover {
	border: 1px none #000;
	background-color: #fff;
	color: #16318c;
}

.heading {
	color: #16318c;
}

.heading-2 {
	margin-top: 0em;
	padding-top: 0em;
	color: #16318c;
}

.text-block {
	color: #16318c;
}

.heading-3 {
	color: #16318c;
}

.heading-4 {
	margin-top: 0.75em;
	color: #16318c;
}

.heading-5 {
	color: #16318c;
}

.heading-6 {
	color: #16318c;
}

.heading-7 {
	color: #16318c;
}

.block-quote {
	border-left-color: #ffdb2b;
	background-color: rgba(172, 173, 175, 0.1);
	color: #444;
}

.heading-8 {
	color: #585858;
}

.heading-9 {
	color: #585858;
}

.heading-10 {
	color: #16318c;
}

.heading-11 {
	color: #16318c;
}

.heading-12 {
	color: #16318c;
}

.heading-13 {
	color: #16318c;
}

.heading-14 {
	color: #2343b1;
}

html.w-mod-js *[data-ix="md-patient-comments-reveal-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="for-two-part-button-list"] {
	-webkit-transform: translate(100%, 0px);
	transform: translate(100%, 0px);
}

html.w-mod-js *[data-ix="display-none-on-page-load"] {
	display: none;
}

html.w-mod-js *[data-ix="for-grid-view-button"] {
	opacity: 0.7500000000000003;
}

html.w-mod-js *[data-ix="preloader-wrap"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

.main-content-column-nested {
	min-height: 300px;
}

@media screen and (max-width: 991px) {
	h1 {
		font-size: 2.4em;
		line-height: 3.1rem;
	}

	.button.header-search-button {
		position: absolute;
		right: 0rem;
		width: 3rem;
		height: 2.875rem;
		opacity: 0.65;
		font-size: 1rem;
	}

	.button.header-search-button:hover {
		opacity: 1;
	}

	.button.slider-button {
		background-color: #16318c;
		color: #fff;
		font-size: 0.9375em;
	}

	.button.slider-button:hover {
		padding-left: 3.13rem;
		background-color: #041d6f;
		color: #fff;
	}

	.container {
		padding-right: 40px;
		padding-left: 40px;
	}

	.container.footer-container {
		padding-right: 0px;
		padding-left: 0px;
	}

	.nav-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		width: 100%;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.nav-link {
		z-index: 140;
		display: block;
		width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		padding: 1rem 1rem 0.875rem;
		background-color: #16318c;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		font-weight: 500;
		text-align: left;
		text-transform: none;
	}

	.nav-link:hover {
		background-color: #041d6f;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	}

	.nav-link.w--current {
		background-color: #041d6f;
		box-shadow: inset 5px 0 0 0 #ffdb2b, 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #fff;
	}

	.nav-link.w--current:hover {
		background-color: #041d6f;
		box-shadow: inset 5px 0 0 0 #ffdb2b, 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #fff;
	}

	.nav-link.two-part-mobile {
		width: 80%;
		float: left;
	}

	.nav-link.two-part-mobile:hover {
		background-color: #041d6f;
	}

	.nav-link.mobile-list-back {
		position: relative;
		padding-left: 2.25rem;
		background-color: rgba(0, 0, 0, 0.25);
		color: hsla(0, 0%, 100%, 0.55);
		cursor: pointer;
	}

	.nav-link.mobile {
		display: block;
	}

	.nav-menu-wrap {
		display: block;
		overflow: hidden;
		margin-top: 183px;
		border-left-style: none;
		background-color: #16318c;
		text-align: left;
	}

	.logo {
		margin-left: 0.75em;
		font-size: 1.25rem;
	}

	.logo.w--current {
		margin-left: 1em;
	}

	.row.footer-signoff-row {
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.row.home-cbox-row-3 {
		margin-bottom: 0rem;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.row.home-cbox-row-1 {
		display: block;
		margin-bottom: 0rem;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		flex: 0 auto;
	}

	.column.footer-signoff-column {
		margin-bottom: 1.5em;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		justify-content: space-between;
	}

	.column.footer-signoff-column.right-signoff-column {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		justify-content: space-between;
	}

	.menu-button {
		height: 3.5rem;
		margin-left: auto;
		padding: 0px 1.25rem 0px 0.75rem;
		float: left;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		order: -1;
		background-color: transparent;
		-webkit-transition: all 250ms ease;
		transition: all 250ms ease;
		font-family: Khand;
		color: #fff;
		line-height: 3.5rem;
		font-weight: 500;
		text-align: center;
		text-transform: uppercase;
	}

	.menu-button:hover {
		background-color: rgba(0, 0, 0, 0.25);
	}

	.menu-button.w--open {
		background-color: #041d6f;
	}

	.menu-button.w--open:hover {
		background-color: rgba(0, 0, 0, 0.5);
	}

	.footer-signoff-section {
		padding-bottom: 0rem;
		text-align: center;
	}

	.footer-signoff-grip {
		margin-right: 0.75rem;
		float: none;
	}

	.footer-column-wrap {
		width: 100%;
		padding-right: 0rem;
		-webkit-box-ordinal-group: 4;
		-webkit-order: 3;
		order: 3;
	}

	.footer-column-wrap.footer-social-column {
		max-width: none;
		padding-right: 0rem;
		-webkit-box-ordinal-group: 3;
		-webkit-order: 2;
		order: 2;
		border-top-style: none;
		text-align: center;
	}

	.footer-column-wrap.footer-updates-column {
		max-width: 100%;
		margin-bottom: -1.5rem;
		padding-bottom: 3rem;
		padding-left: 1.5rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		justify-content: flex-start;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		order: 1;
		-webkit-flex-basis: 100%;
		flex-basis: 100%;
		border-style: none;
		border-right-width: 1px;
		border-right-color: rgba(0, 0, 0, 0.1);
		border-bottom-width: 1px;
		border-bottom-color: rgba(0, 0, 0, 0.15);
	}

	.header-content-right-wrap {
		margin-right: 1em;
		padding-bottom: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		flex-direction: row;
		-webkit-align-self: center;
		align-self: center;
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		width: 100%;
		max-width: none;
		float: none;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.form-field.right-sidebar-field {
		margin-bottom: 1.5em;
	}

	.form-field.right-sidebar-field.miles-from {
		width: 100%;
		margin-bottom: 0.25em;
	}

	.form-field.right-sidebar-field.zip-code {
		width: 100%;
	}

	.form-field.header-search-field {
		width: 70%;
		height: 2.875rem;
		padding-left: 1em;
		-webkit-box-flex: 100%;
		-webkit-flex: 100%;
		flex: 100%;
		font-size: 0.8125rem;
	}

	.form-field.header-search-field:focus {
		width: 280px;
		min-width: 280px;
	}

	.secondary-nav-list {
		margin-top: 0rem;
		margin-bottom: 0em;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		order: 1;
	}

	.secondary-nav-list-item {
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-align-self: stretch;
		align-self: stretch;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		flex-grow: 1;
		-webkit-flex-basis: auto;
		flex-basis: auto;
		border-left-width: 1px;
		border-left-color: rgba(0, 0, 0, 0.25);
	}

	.right-sidebar-column {
		margin-top: 3rem;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.sidebar-section-wrap {
		margin-left: 0em;
		padding-right: 0px;
		padding-left: 0px;
	}

	.secondary-nav-icon {
		width: 1.375rem;
		height: 1.375rem;
		margin-top: -0.1875em;
		margin-right: 0.5rem;
		padding: 2px;
		background-color: transparent;
	}

	.secondary-nav-icon.idea-icon {
		width: 2.25em;
		height: 2.25em;
		margin-top: 0em;
	}

	.secondary-nav-list-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-top: 0.22rem;
		padding-right: 1.25rem;
		padding-left: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		align-items: center;
		font-family: 'Roboto Condensed', sans-serif;
		color: #fff;
		font-size: 1.125rem;
		font-weight: 400;
	}

	.secondary-nav-list-link:hover {
		background-color: rgba(0, 0, 0, 0.25);
		-webkit-transform: none;
		transform: none;
		color: #fff;
	}

	.secondary-nav-list-link:active {
		background-color: transparent;
	}

	.secondary-nav-list-link:focus {
		color: #fff;
	}

	.slider {
		height: auto;
		max-height: none;
		min-height: 400px;
	}

	.slider-arrow {
		top: 400px;
		bottom: auto;
		width: 4rem;
		height: 3rem;
		margin-top: -3rem;
		background-color: transparent;
		-webkit-transform: none;
		transform: none;
		color: #e4e7eb;
		font-size: 1.375rem;
	}

	.slider-arrow:hover {
		background-color: transparent;
	}

	.slider-arrow:active {
		background-color: transparent;
	}

	.slider-indicator {
		top: 400px;
		bottom: auto;
		margin-top: -1em;
	}

	.home-image-link {
		height: 25vw;
		max-height: 240px;
	}

	.home-image-link.large-image-link {
		min-height: 220px;
		min-width: 45%;
	}

	.home-content-box-wrap {
		margin-bottom: 1.5rem;
	}

	.home-content-box-wrap.intro-content-box-wrap {
		margin-bottom: 0rem;
		padding-bottom: 0rem;
	}

	.home-content-box-wrap.hero-cbox-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		align-items: center;
	}

	.home-content-box-title.intro-cbox-title {
		font-size: 2.25em;
	}

	.home-cbox-learn-more.learn-tabmobile-row-1 {
		display: block;
	}

	.slider-image {
		position: static;
		height: 400px;
	}

	.slider-caption-outer-wrap {
		position: static;
		top: 107px;
		display: block;
		width: 100%;
		max-width: none;
		margin-right: auto;
		margin-bottom: 0px;
		margin-left: auto;
		padding: 0rem 40px 2rem;
		-webkit-transform: none;
		transform: none;
		color: #3e3f3d;
	}

	.inside-page-header-section {
		display: none;
	}

	.inside-content-beckground {
		margin-top: 0px;
		padding-top: 1.5rem;
		padding-bottom: 2rem;
	}

	.main-content-wrap.nested-content-wrap {
		margin-right: 0em;
	}

	.menu-button-icon {
		float: left;
		-webkit-align-self: center;
		align-self: center;
		font-size: 2rem;
		line-height: 3.5rem;
	}

	.menu-button-text {
		margin-left: 0.125rem;
		padding-top: 0.125em;
		float: left;
		font-family: 'Roboto Condensed', sans-serif;
		color: #fff;
		font-size: 1.125rem;
		line-height: 3.4125rem;
		letter-spacing: 0.02em;
	}

	.header-menu-inside-wrap {
		max-width: 100%;
	}

	.home-cbox-text-wrap {
		font-size: 0.9375rem;
	}

	.home-cbox-text-wrap.hero-cbox-text-wrap {
		margin-left: 3%;
		padding-top: 0rem;
		-webkit-align-self: center;
		align-self: center;
		font-size: 1.0625rem;
	}

	.home-cbox-text-wrap.intro-cbox-text-wrap {
		max-width: 90%;
		font-size: 1.25rem;
	}

	.slider-caption-title {
		display: block;
		margin-bottom: 0em;
		padding-top: 0em;
		background-color: transparent;
		box-shadow: none;
		font-size: 2.5em;
		line-height: 3rem;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.link.breadcrumb-link {
		color: #797676;
	}

	.link.breadcrumb-link:hover {
		color: #3e3f3d;
	}

	.loc-miles-from-zip-wrap {
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.loc-type-form-checkbox {
		width: 33.33%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 33.33%;
		margin-bottom: 0.75em;
	}

	.loc-type-form-checkbox.sidebar-checkbox.select-all {
		width: 100%;
		margin-top: 0.75em;
	}

	.loc-type-form-checkboxes-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		justify-content: flex-start;
	}

	.loc-sidebar-form-reveal {
		height: 0px;
	}

	.loc-sidebar-search-reveal-button {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		justify-content: flex-start;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.loc-refine-search-checkbox-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		padding-top: 0.75em;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 0%;
		margin-left: 0%;
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.nav-drop-toggle {
		display: block;
	}

	.nav-drop-toggle-link {
		display: block;
		padding-left: 1rem;
		text-align: left;
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		background-color: #3e3f3d;
	}

	.secondary-header-item-wrap {
		margin-right: 0rem;
		margin-left: 0rem;
		font-size: 1.125rem;
	}

	.header-content-right-row {
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
	}

	.secondary-header-item-cta-link {
		margin-left: 1.5rem;
		padding-top: 0.8125em;
		padding-bottom: 0.625em;
	}

	.header-search-wrap {
		margin-left: 0rem;
		padding: 0rem 0rem 0px;
		background-color: transparent;
		box-shadow: none;
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
	}

	.header-search-form {
		position: relative;
		padding: 0rem;
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
	}

	.header-mobile-search-reveal {
		overflow: hidden;
	}

	.slider-caption-inner-wrap {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.slider-caption-heading {
		background-color: transparent;
		box-shadow: none;
	}

	.home-content-box-section-wrap {
		padding-top: 2.5em;
		padding-bottom: 0em;
	}

	.slider-button-arrow-span {
		background-color: hsla(0, 0%, 100%, 0.25);
		background-image: var( --black-arrow-right );
		background-size: 12px 12px;
	}

	.intro-cbox-section {
		margin-top: 1em;
		padding-top: 2em;
		padding-bottom: 2em;
	}

	.footer-flex-row {
		padding-right: 20px;
		padding-left: 20px;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-signoff-acrobat-link {
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		order: -1;
	}

	.slider-image-overlay {
		display: none;
	}

	.footer-contact-wrap {
		padding-right: 1rem;
	}

	.footer-social-block {
		padding-right: 1rem;
		text-align: left;
	}

	.footer-notifications-image {
		width: 8vw;
		height: 8vw;
		max-height: 5rem;
		max-width: 5rem;
		margin-right: 4vw;
		margin-left: 4vw;
		-webkit-align-self: center;
		align-self: center;
	}

	.cb-head-wrapper.sponsorships-header-wrapper {
		padding-left: 0rem;
	}

	.clients-slider {
		margin-right: 0.75rem;
		margin-left: 0.75rem;
	}

	.home-content-section {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.home-content-section.sponsors-section {
		padding-bottom: 3rem;
	}

	.container-2 {
		max-width: 991px;
	}

	.cb-heading.clients-cb-head {
		font-size: 2.014rem;
		line-height: 2.714rem;
	}

	.clients-slider-slide {
		width: 25%;
	}

	.header-contact-us.learn-tabmobile-row-1 {
		display: block;
	}
}

@media screen and (max-width: 767px) {
	body {
		font-size: 0.875rem;
		line-height: 1.375rem;
	}

	h1 {
		font-size: 2.25em;
		line-height: 2.55rem;
	}

	h2 {
		font-size: 1.8rem;
		line-height: 2.3rem;
	}

	h3 {
		font-size: 1.5rem;
		line-height: 1.9rem;
	}

	h4 {
		font-size: 1.3rem;
		line-height: 1.8rem;
	}

	h5 {
		font-size: 1.15rem;
		line-height: 1.6rem;
	}

	p {
		font-size: 1.05rem;
		line-height: 1.55rem;
	}

	ul {
		font-size: 1.05rem;
		line-height: 1.55rem;
	}

	blockquote {
		font-size: 1.2rem;
		line-height: 1.8rem;
	}

	.button.secondary.wishlist {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		flex: 1;
	}

	.button.header-search-button {
		width: 15%;
		height: 3.25rem;
		font-size: 1.125rem;
	}

	.button.slider-button {
		background-color: #16318c;
		color: #fff;
		font-size: 1.0625em;
	}

	.button.slider-button:hover {
		padding-left: 3.13rem;
		background-color: #041d6f;
		color: #fff;
	}

	.container {
		padding-right: 32px;
		padding-left: 32px;
	}

	.nav-link {
		font-size: 1.125rem;
		letter-spacing: 0.04em;
	}

	.nav-menu-wrap {
		margin-top: 170px;
	}

	.logo {
		margin-top: 1em;
		margin-bottom: 1em;
		margin-left: 0.5em;
		padding-left: 0px;
	}

	.footer-section {
		background-color: #f8f9fa;
		background-image: none;
	}

	.column.shop-detail-image-col {
		padding-right: 0px;
	}

	.column.footer-signoff-column {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		text-align: left;
	}

	.logo-image {
		width: 300px;
	}

	.footer-signoff-section {
		padding-bottom: 0rem;
	}

	.copyright-text {
		margin-right: 0rem;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		flex-grow: 1;
		text-align: center;
	}

	.footer-signoff-grip {
		text-align: right;
	}

	.footer-column-wrap {
		margin-top: 0.5rem;
		margin-bottom: 0rem;
		padding-top: 0.5rem;
		padding-right: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		justify-content: flex-start;
	}

	.footer-column-wrap.footer-social-column {
		padding-top: 0rem;
		padding-bottom: 0.5rem;
	}

	.footer-column-wrap.footer-updates-column {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		order: 1;
		-webkit-flex-basis: 100%;
		flex-basis: 100%;
		border-right-style: none;
	}

	.header-content-right-wrap {
		margin-right: 1em;
	}

	.form-field.header-search-field {
		height: 3.25rem;
		font-size: 1em;
	}

	#session-message-wrapper .success {
		font-size: 1rem;
	}

	#session-message-wrapper .error {
		font-size: 1rem;
	}

	.secondary-nav-list {
		max-width: 50%;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		flex: 1;
	}

	.secondary-nav-list-item {
		max-width: 100%;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		flex: auto;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.secondary-nav-icon.idea-icon {
		width: 1.5em;
		height: 1.5em;
		margin-right: 0.375rem;
		margin-bottom: 0rem;
		padding: 3px 3px 5px;
	}

	.secondary-nav-list-link {
		padding-top: 2px;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		flex: 1;
	}

	.slider-section {
		background-color: #f8f9fa;
	}

	.slider {
		height: auto;
		max-height: none;
		min-height: 300px;
	}

	.slider-arrow {
		top: 300px;
		bottom: auto;
		width: 3.25rem;
		margin-top: -3rem;
	}

	.slider-indicator {
		top: 300px;
		bottom: auto;
		margin-bottom: 0em;
	}

	.home-image-link {
		height: 50vw;
		max-height: 300px;
	}

	.home-image-link.large-image-link {
		width: 100%;
		max-height: 260px;
	}

	.home-content-box-wrap {
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.home-content-box-wrap.intro-content-box-wrap {
		width: auto;
		margin-right: 10px;
		margin-left: 10px;
		float: none;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		border-bottom-style: none;
	}

	.home-content-box-wrap.hero-cbox-wrap {
		margin-bottom: 1.5rem;
		padding-bottom: 3rem;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		border-bottom-color: rgba(0, 0, 0, 0.15);
	}

	.home-content-box-wrap.hero-cbox-wrap.last-hero-cbox {
		margin-bottom: 0rem;
		border-bottom-style: none;
	}

	.home-content-box-wrap.last-cbox-wrap {
		border-bottom-style: none;
	}

	.home-content-box-title {
		line-height: 1.125em;
	}

	.home-cbox-learn-more {
		margin-bottom: 1rem;
	}

	.slider-image {
		position: relative;
		height: 300px;
	}

	.slider-caption-outer-wrap {
		top: 100%;
		max-width: 100%;
		-webkit-transform: none;
		transform: none;
	}

	.shop-item-wrap.flex {
		width: 48%;
	}

	.inside-page-header-content-wrap {
		margin-right: 10px;
		margin-left: 10px;
	}

	.inside-page-header-section {
		height: 60px;
	}

	.inside-content-beckground {
		padding-top: 1rem;
	}

	.main-content-wrap.no-sidebars {
		margin-right: 10px;
		margin-left: 10px;
	}

	.home-cbox-text-wrap {
		padding-bottom: 2.75rem;
	}

	.home-cbox-text-wrap.hero-cbox-text-wrap {
		width: 100%;
		margin-left: 0%;
		padding-top: 1.125em;
		float: left;
	}

	.home-cbox-text-wrap.intro-cbox-text-wrap {
		max-width: none;
		font-size: 1.125rem;
		text-align: left;
	}

	.slider-caption-title {
		background-color: transparent;
		box-shadow: none;
		font-size: 2.25rem;
		line-height: 2.75rem;
	}

	.footer-social-icons-wrap {
		margin-bottom: 0em;
		padding-right: 10px;
	}

	.footer-social-icon-link {
		margin-right: 10px;
	}

	.footer-social-icon-image {
		width: 36px;
		height: 36px;
	}

	.home-cbox-dynamic-feed-item-wrap {
		width: 100%;
		margin-right: 0%;
	}

	.footer-link {
		font-size: 1.125rem;
	}

	.link.content-box-title-link:hover {
		border-bottom-color: transparent;
	}

	.pagination-middle-wrap {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		flex: 0 auto;
	}

	.pagination-wrap.flex-pagination {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		justify-content: space-between;
	}

	.loc-type-form-checkbox {
		width: 50%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 50%;
	}

	.pagination-right-side-wrap {
		display: none;
	}

	.header-container {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.secondary-header-item-wrap {
		font-size: 1.125rem;
	}

	.secondary-header-item-cta-link {
		margin-left: 0rem;
	}

	.header-search-wrap {
		padding-bottom: 1px;
	}

	.shop-detail-attributes-wrap {
		margin-top: 1.5em;
	}

	.slider-caption-inner-wrap {
		padding-right: 0px;
		padding-left: 0px;
	}

	.slider-caption-heading {
		margin-top: 0em;
		background-color: transparent;
		box-shadow: none;
		color: #3e3f3d;
		font-size: 1.3rem;
		line-height: 1.8rem;
		text-shadow: none;
	}

	.home-content-box-section-wrap {
		padding-bottom: 0em;
	}

	.slider-button-arrow-span {
		border-right-color: hsla(0, 0%, 100%, 0.2);
		background-color: rgba(0, 0, 0, 0.2);
		background-image: var( --white-arrow-right );
		background-size: 7px;
	}

	.intro-cbox-section {
		margin-top: 0em;
	}

	.footer-flex-row {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		flex-direction: row;
	}

	.footer-contact-wrap {
		padding-right: 1rem;
	}

	.footer-social-block {
		padding-left: 0rem;
	}

	.footer-notifications-image {
		width: 10vw;
		height: 10vw;
		margin-right: 5%;
		margin-left: 5%;
	}

	.content-image-example {
		width: 100%;
		margin-bottom: 15px;
		margin-left: 0px;
		float: none;
	}

	.cb-head-wrapper.sponsorships-header-wrapper {
		padding-left: 0rem;
	}

	.container-2 {
		max-width: 767px;
	}

	.cb-heading.clients-cb-head {
		line-height: 2.714rem;
	}

	.clients-slider-slide {
		width: 33.33%;
	}

	.header-contact-us {
		margin-bottom: 1rem;
	}

	.header-contact-us.contact-us-link {
		margin-bottom: 0rem;
	}
}

@media screen and (max-width: 479px) {
	h1 {
		font-size: 2em;
		line-height: 2.3rem;
	}

	h2 {
		font-size: 1.6rem;
		line-height: 2rem;
	}

	blockquote {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.button.secondary.wishlist {
		width: 100%;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		flex: 0 auto;
	}

	.button.header-search-button {
		width: 20%;
		height: 3rem;
	}

	.button.add-to-cart {
		max-width: 100%;
		margin-right: 0em;
	}

	.button.slider-button {
		font-size: 1em;
	}

	.nav-container {
		border-top: 1px solid hsla(0, 0%, 100%, 0.5);
	}

	.nav-menu-wrap {
		margin-top: 204px;
	}

	.logo {
		right: 0.375em;
		margin: 1.25em auto;
		font-size: 1rem;
	}

	.logo.w--current {
		width: 100%;
	}

	.row.footer-signoff-row {
		padding-right: 10px;
		padding-left: 10px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
	}

	.column.footer-signoff-column {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column.footer-signoff-column.right-signoff-column {
		margin-top: 0rem;
		margin-bottom: 0em;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
	}

	.logo-image {
		width: 300px;
	}

	.menu-button {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.footer-signoff-section {
		padding-bottom: 0rem;
	}

	.copyright-text {
		margin-left: 0.5rem;
		text-align: center;
	}

	.footer-signoff-list {
		margin-top: 0.75rem;
		text-align: center;
	}

	.footer-signoff-list-item {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.footer-signoff-grip {
		margin-right: 1rem;
		margin-bottom: 1.5rem;
		margin-left: 0;
		padding-left: 0;
	}

	.footer-column-wrap {
		width: 100%;
		padding-right: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}

	.footer-column-wrap.footer-updates-column {
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-flex-wrap: wrap-reverse;
		flex-wrap: wrap-reverse;
		text-align: center;
	}

	.header-content-right-wrap {
		display: block;
		margin-right: 0em;
		-webkit-box-align: center;
		-webkit-align-items: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		flex: 1;
		border: 1px none #000;
	}

	.form-field.header-search-field {
		height: 3rem;
	}

	.secondary-nav-list {
		max-width: 136px;
	}

	.secondary-nav-list-item {
		max-width: none;
		margin-right: 0.25rem;
	}

	.secondary-nav-icon {
		margin-top: 0em;
		margin-right: 0rem;
	}

	.secondary-nav-icon.contact-us-nav-icon {
		width: 1.5rem;
		height: 1.5rem;
	}

	.secondary-nav-text {
		display: none;
	}

	.secondary-nav-list-link {
		padding-top: 0px;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.slider-arrow {
		top: 230px;
	}

	.slider-indicator {
		top: 230px;
	}

	.home-image-link {
		height: 45vw;
		min-height: 120px;
	}

	.home-image-link.large-image-link {
		min-height: 180px;
	}

	.home-content-box-wrap {
		border-bottom-color: rgba(0, 0, 0, 0.15);
	}

	.home-content-box-wrap.hero-cbox-wrap {
		border-bottom-color: rgba(0, 0, 0, 0.15);
	}

	.home-content-box-title.intro-cbox-title {
		font-size: 1.5625em;
	}

	.slider-image {
		height: 230px;
	}

	.slider-caption-outer-wrap {
		padding-bottom: 0rem;
	}

	.shop-item-wrap.flex {
		width: 100%;
		margin-right: 0%;
		margin-left: 0%;
	}

	.menu-button-text {
		display: none;
	}

	.home-cbox-text-wrap {
		width: 100%;
		float: none;
	}

	.home-cbox-text-wrap.hero-cbox-text-wrap {
		font-size: 0.9375rem;
	}

	.slider-caption-title {
		font-size: 2em;
		line-height: 2.3rem;
	}

	.footer-social-icons-wrap {
		padding-right: 0px;
		padding-left: 0px;
	}

	.footer-social-icon-link {
		margin-right: 12px;
		margin-bottom: 0px;
		margin-left: 12px;
	}

	.footer-newsletter-wrap {
		width: 100%;
		padding-left: 0px;
		float: none;
	}

	.pagination-middle-wrap {
		min-width: 100%;
		margin-top: 0.75em;
		padding-top: 0.75em;
		border-top: 1px solid rgba(84, 159, 196, 0.25);
	}

	.pagination-wrap.flex-pagination {
		padding-right: 0%;
		padding-left: 0%;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.loc-type-form-checkbox {
		width: 100%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 100%;
	}

	.pagination-left-side-wrap {
		width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		justify-content: space-between;
	}

	.header-container {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.header-content-right-row {
		margin-top: 0rem;
		margin-bottom: 0rem;
		border: 1px none #000;
	}

	.shop-detail-form-buttons-wrap {
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.slider-caption-heading {
		font-size: 1.2rem;
		line-height: 1.7rem;
	}

	.footer-flex-row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		flex-direction: column;
	}

	.footer-signoff-acrobat-link {
		margin-right: 0rem;
		margin-bottom: 1.5rem;
		margin-left: 1rem;
	}

	.footer-contact-wrap {
		width: 100%;
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.footer-social-block {
		width: 100%;
		margin-bottom: 1.5rem;
		padding-right: 0rem;
		text-align: center;
	}

	.footer-notifications-image {
		width: 15vw;
		height: 15vw;
		margin-bottom: 2vw;
	}

	.clients-slider-image {
		max-height: 75px;
		max-width: 150px;
	}

	.clients-slider {
		height: 125px;
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.home-content-section {
		padding-top: 1rem;
	}

	.home-content-section.sponsors-section {
		padding-bottom: 3rem;
	}

	.container-2 {
		max-width: none;
	}

	.clients-slider-slide {
		width: 100%;
		height: 125px;
		margin-top: 0px;
	}

	.clients-slider-slide.slide-1 {
		height: 120px;
	}

	.header-contact-us.contact-us-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		border-style: none;
		background-position: 32% 50%;
	}
}


/* Main Table */
table.styled {
	width: 100%;
	margin: 0 0 1em;
	background-color: white;
	border-spacing: 0px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px;
}

/* Vertical Column Dividers */
table.styled th,
table.styled td {
	border-right: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(255, 255, 255, 0.75);
	vertical-align: top;
}

/* Last Vertical Column Divider */
table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

/* Title */
table.styled thead tr th {
	font-weight: normal;
	text-transform: none;
	letter-spacing: 0;
	color: #ffffff;
	background-color: #16318c;
	border-bottom: 2px solid #ffdb2b;
	padding: 0.625rem;
	text-align: left;
}

/* Column or Row Header */
table.styled th {
	font-weight: normal;
	letter-spacing: 0;
	padding: 0.625rem;
	background-color: #2343b1;
	border-bottom: 0;
	color: white;
	text-align: left;
}

/* This code controls any visual distinctions for link decoration */
table.styled th a {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
}

/* Depending on your design, you may want this for the link hover state */
table.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
}

/* Data */
table.styled td {
	padding: 0.375em 0.625rem;
}

/* Even Rows */
table.striped tr:nth-child(even) {
	background: #ebedf0;
}

/* Odd Rows */
table.striped tr:nth-child(odd) {
	background: white;
}


/** Mobile Menu **/
.mobile-navigation-menu {
	display: none !important;
}

.mm-wrapper_opened .mobile-navigation-menu {
	display: inherit !important;
}

.mm-navbar {
	background: #16318c;
}

.mm-navbar__title {
	display: none;
}

.mm-panels > .mm-panel {
	background: #16318c;
}

.mm-menu a,
.mm-menu a:active,
.mm-menu a:hover,
.mm-menu a:link,
.mm-menu a:visited {
	color: #ffffff;
}
/** end mobile menu **/

.remove-border-bottom {
	border-bottom: none;
}
